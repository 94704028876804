import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name: "pagination",
    model: {
        prop: "currentPage",
        event: "change",
    },
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {
        currentPage: Number,
        pageLimit: {
            type: Number,
            default: 7,
        },
        totalPageCount: Number,
        showFirstAndLast: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            paginationPages: [],
            ellipsis: false,
        };
    },
    computed: {
        getPages() {
            let items = [];
            if (this.totalPageCount <= this.pageLimit) {
                for (let index = 0; index < this.totalPageCount; index++) {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === this.currentPage - 1,
                    };
                    items[index] = page;
                }
            } else {
                const halfPageRange = Math.floor(this.pageLimit / 2);
                let setPageItem = (index) => {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === this.currentPage - 1,
                    };
                    items[index] = page;
                };
                let selectedRangeLow = 0;
                if (this.currentPage - halfPageRange > 0) {
                    selectedRangeLow = this.currentPage - 1 - halfPageRange;
                }
                let selectedRangeHigh = selectedRangeLow + this.pageLimit - 1;
                if (selectedRangeHigh >= this.totalPageCount) {
                    selectedRangeHigh = this.totalPageCount - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageLimit + 1;
                }
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.totalPageCount - 1; i++) {
                    setPageItem(i);
                }
            }
            return items.filter((item) => item).map((ele) => ele.content);
        },
    },
    mounted() {},
    methods: {
        onPaginate(pageNo) {
            this.$emit("change", pageNo);
        },
        onNextPage() {
            if (this.currentPage < this.totalPageCount) this.$emit("change", this.currentPage + 1);
        },
        onPrevPage() {
            if (this.currentPage > 1) this.$emit("change", this.currentPage - 1);
        },
        onLastPage() {
            this.$emit("change", this.totalPageCount);
        },
        onFirstPage() {
            this.$emit("change", 1);
        },
    },
};
