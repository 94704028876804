import { mapActions, mapGetters } from "vuex";
import appHeader from "@shared/app-header";
import appSidebar from "@/components/app-sidebar";
import alert from "@/components/alert-box";
import pagination from "@/components/pagination";
import { checkPermission } from "@shared/utils/functions";

export default {
  name: 'dashboard',

  components: {
    appHeader,
    appSidebar,
    alert,
    pagination,
  },

  data () {
    return {
      isLoading: true,
    }
  },

  title: "Dashboard",

  computed: {
    ...mapGetters([
      "getTenantId",
      "getNotificationCount",
      "getThemeUseDefault",
      "getThemeColors",
      "getDefaultColors",
    ]),

    themeColors() {
      const colors = this.getThemeUseDefault === false ? this.getThemeColors : this.getDefaultColors;
      return Object.values(colors).reduce((result, color) => {
        if (color.value) result[color.var] = color.value;
        return result;
      }, {})
    },
  },

  async created () {
    this.isLoading = true;
    await this.fetchNewTenantId();
    await this.initApp();
    this.isLoading = false;
  },

  methods: {
    checkPermission,
    ...mapActions([
      "fetchNotificationCount",
      "fetchUIConf",
      "fetchTenantId",
    ]),

    async fetchNewTenantId() {
      await this.fetchNotificationCount()
    },

    async initApp() {
      console.log("initApp dashboard")
      try {
        await this.fetchTenantId();
      } catch(e) {
        this.$toast.error("Initialization error");
      } 

      if (this.getTenantId) {
          await this.fetchUIConf(this.getTenantId);
      }
    }
  },
}
