<template>
  <button class="btn no-animation group normal-case relative btn-ghost hover:bg-blue-50" title="Notifications"
  @click="$router.push({name: 'pending-activity'})">
      <span class="absolute -top-1 -right-1 rounded-full py-0.5 px-1.5 text-xs text-white-text font-bold bg-red-500 " v-if="count">{{count}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi text-primary group-hover:text-primary-300  bi-bell-fill" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
        </svg>
  </button>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0
        }
    },
    computed: {
    }
}
</script>

<style>

</style>