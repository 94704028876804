<template>
  <div>
    <!-- <DropDownIcon @click="handleDropDownIcon" class="cursor-pointer" /> -->

    <div class=" h-8 flex-col justify-start items-end  ">
      <button class="py-1  rounded flex justify-between items-center  text-slate-900 text-opacity-75 text-sm relative"
        @click="toggleMenu" @blur="closeDropdown">
        <CreditCard />
        <div class="flex-col px-4">
          <p class="text-base-content-300 mb-0  text-left text-sm">{{ this.primary?.name }}</p>
          <p class="text-base-content-300 mb-0  text-sm"><span class="text-red-600">({{this.primary?.user_available_credits}}/{{this.primary?.issued_credits}})</span> </p>
          <div class="h-1/2 w-full bg-cst-neutral ">
            <div class="h-1 bg-cst rounded-md"
              :style="{ width: (this.primary?.user_available_credits * 100) / this.primary?.issued_credits + '%' }">
            </div>
          </div>
        </div>
        <font-awesome-icon class="ml-auto mr-2 transition-transform transform ease-out duration-300"
          :class="showMenu ? ' rotate-180' : 'rotate-0'" :icon="['fas', 'chevron-down']" color="black" size="xs" />
      </button>
      <div v-if="this.showMenu" class="bg-white rounded-md shadow py-2  absolute cursor-pointer">
        <div v-if="this.showAll">
          <div v-for="item in handlelessList" :key="item.id" class="text-sm font-small h-auto w-full flex ">
            <div @mousedown="handlePrimary(item)" class="flex justify-between items-center dropdown_content p-2 w-full">
              <div class="flex justify-between  items-center">
                <CreditCard class="mx-2" />
                <div class="flex-col mx-2">
                  <!-- <div class="">
                    {{ item.name }}
                  </div> -->
                  <div class="text-sublabel " :title="item?.description">{{ item?.description.length > 20 ?
                    `${item.description.substr(0, 20)}...` : item?.description }}</div>
                  <div>
                     {{item?.unique_id}}
                  </div>  
                </div>
              </div>
              <div class="flex justify-between  items-center">
                <div class="flex-col mx-2">
                  <div class="mx-2">         
                    ({{ item.user_available_credits }}/{{item.issued_credits}})
                  </div>
                  <div>
                    <div class="h-1/2 w-full bg-cst-neutral ">
                      <div class="h-1 bg-cst rounded-md"
                        :style="{ width: item.user_available_credits * 100 / item.issued_credits + '%' }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">
                  <UserIcon />
                </div>
              </div>
            </div>
          </div>
          <div class="text-sm font-small cst-label  h-auto p-2 flex flex-items" v-if="listData.length > 4"
            @click="handleLessDetails">
            View all Details
          </div>
        </div>
        <div v-else class="w-auto  py-2 px-1">
          <div v-for="item in handleList" :key="item.id" class="text-sm font-small h-auto w-full flex ">
            <div @mousedown="handlePrimary(item)" class="flex justify-between items-center dropdown_content p-2 w-full">
              <div class="flex justify-between  items-center">
                <CreditCard class="mx-2" />
                <div class="flex-col mx-2">
                  <!-- <div class="">
                    {{ item?.name }}
                  </div> -->
                  <div class="text-sublabel " :title="item?.description">{{ item?.description.length > 20 ?
                    `${item.description.substr(0, 20)}...` : item?.description }}</div>
                  <div>
                     {{item?.unique_id}}
                  </div>   
                </div>
              </div>
              <div class="flex justify-between  items-center">
                <div class="flex-col mx-2">
                  <div class="mx-2">
                    ({{ item.user_available_credits}} / {{item.issued_credits}})
                  </div>
                  <div>
                    <div class="h-1/2 w-full bg-cst-neutral ">
                      <div class="h-1 bg-cst rounded-md"
                        :style="{ width: (item.user_available_credits * 100) / item.issued_credits + '%' }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">
                  <UserIcon />
                </div>
              </div>
            </div>
          </div>
          <div class="dropdown_content text-sm font-small cst-label  h-auto p-2 flex flex-items"
            v-if="listData.length > 4" @click="handleMoreDetails">
            View less Details
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreditCard from "@shared/assets/credit-card-bold.svg"
// import DropDownIcon from "@shared/assets/dropdown-icon.svg"
import UserIcon from "../../assets/svg/user.svg";
// import UserIcon from "../../assets/svg/user.svg";
import backendaxios from "../../light-ray/axios";
// import BorderBottom from "@shared/assets/border-botton.svg"

export default {
  data() {
    return {
      showMenu: false,
      showAll: false,
      primary: {}
    }
  },
  components: {
    CreditCard,
    UserIcon
  },
  methods: {
    toggleMenu() { this.showMenu = !this.showMenu },
    closeDropdown() { this.showMenu = false },
    async handlePrimary(item) {
      const url = await backendaxios.get(`user-credit-pack?credit_pack_id=${item?.credit_pack_id}`);
      if (url) {
        this.$toast.success(url.data.message)
        this.showMenu = false;
        this.primary = item;
      }
    },
    handleMoreDetails() {
      this.showAll = true;
    },
    handleLessDetails() {
      this.showAll = false;
    }

  },
  props: {
    primaryCreditPack: Object,
    listData: Array,
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    handleList() {
      return this.listData
    },
    handlelessList() {
      return this.listData.filter((item, indx) => indx < 4)
    },
  },
  mounted() {
    this.primary = { ...this.primaryCreditPack }
  }
};
</script>

<style>
.dropdown_content:hover {
  background: #FFEBEB;
}

.m-width {
  min-width: fit-content
}

.text-sublabel {
  font-size: 10px;
}

.dd-wrapper {
  background: white;
  padding: 10px;
}

.bg-cst {
  background: #CE1B22;
}

.bg-cst-neutral {
  background: #E8E8E8;
}

.flex-items {
  align-items: center;
}

.cst-label {
  color: #CE1B22;

}
</style>